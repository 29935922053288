<template>
  <section
    class="relative z-20 overflow-hidden bg dark:bg-dark pt-20 pb-12 lg:pt-[70px] lg:pb-[90px]"
  >
    <div class="container mx-auto">
      <div class="-mx-4 flex flex-wrap">
        <div class="w-full px-4 lg:w-1/3">
          <RemPrimaryButton
            :method="
              () => {
                addNewState();
              }
            "
            :label="$t('message.addNewState')"
            class="mb-8"
          >
          </RemPrimaryButton>
          <!--eslint-disable-->
          <template :key="st.id" v-for="st in state.states">
            <div
              class="mb-8 w-full rounded-lg bg-secondary p-4 shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)] dark:bg-dark-2 dark:shadow-[0px_20px_95px_0px_rgba(0,0,0,0.30)] sm:p-8 lg:px-6 xl:px-8"
            >
              <div class="faq-btn flex w-full text-left">
                <div
                  @click="handleToggle(st.id)"
                  class="hover:cursor-pointer mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg bg-secondary text-primary dark:bg-white/5"
                >
                  <svg
                    class="fill-gray-700 stroke-gray-700 duration-200 ease-in-out hover:fill-white hover:stroke-white"
                    :class="{ 'rotate-180': activeState === st.id }"
                    width="17"
                    height="10"
                    viewBox="0 0 17 10"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.28687 8.43257L7.28679 8.43265L7.29496 8.43985C7.62576 8.73124 8.02464 8.86001 8.41472 8.86001C8.83092 8.86001 9.22376 8.69083 9.53447 8.41713L9.53454 8.41721L9.54184 8.41052L15.7631 2.70784L15.7691 2.70231L15.7749 2.69659C16.0981 2.38028 16.1985 1.80579 15.7981 1.41393C15.4803 1.1028 14.9167 1.00854 14.5249 1.38489L8.41472 7.00806L2.29995 1.38063L2.29151 1.37286L2.28271 1.36548C1.93092 1.07036 1.38469 1.06804 1.03129 1.41393L1.01755 1.42738L1.00488 1.44184C0.69687 1.79355 0.695778 2.34549 1.0545 2.69659L1.05999 2.70196L1.06565 2.70717L7.28687 8.43257Z"
                      fill=""
                      stroke=""
                    />
                  </svg>
                </div>
                <div class="w-full">
                  <div class="flex flex-row justify-between faq-btn w-full">
                    <button
                      @click="
                        setStateId(st.id);
                        state.isTransition = false;
                        state.isState = true;
                      "
                    >
                      <h4 class="mt-1 text-lg font-semibold text-gray-700 hover:text-white">
                        {{ st.name }}
                      </h4>
                    </button>
                    <div class="flex flex-row">
                      <button
                        @click="addNewTransition(st.id)"
                        class="hover:cursor-pointer mr-5 flex h-10 w-full max-w-[40px] items-center justify-center bg-secondary rounded-lg text-primary dark:bg-white/5"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          class="fill-gray-700 stroke-gray-700 hover:fill-white hover:stroke-white"
                        >
                          <path
                            d="M17,13H13V17H11V13H7V11H11V7H13V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                            fill=""
                            stroke=""
                          />
                        </svg>
                      </button>
                      <button
                        @click="editState(st.id)"
                        class="hover:cursor-pointer mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg bg-secondary text-primary dark:bg-white/5"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          class="fill-gray-700 stroke-gray-700 hover:fill-white hover:stroke-white"
                        >
                          <path
                            d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
                            fill=""
                            stroke=""
                          />
                        </svg>
                      </button>
                      <button
                        @click="setConfirmation('deleteStateConfirm', st.id)"
                        class="hover:cursor-pointer mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg bg-secondary text-primary dark:bg-white/5"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          class="fill-gray-700 stroke-gray-700 hover:fill-white hover:stroke-white"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z"
                            fill=""
                            stroke=""
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="activeState === st.id"
                v-for="transition in transitions"
                :key="transition.id"
                class="flex flex-row justify-left"
              >
                <button
                  @click="editTransition(transition.id)"
                  class="mt-3 hover:cursor-pointer flex h-10 w-full max-w-[40px] items-center align-center justify-center rounded-lg bg-secondary text-primary dark:bg-white/5"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    class="fill-gray-700 stroke-gray-700 hover:fill-white hover:stroke-white"
                  >
                    <path
                      d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
                      fill=""
                      stroke=""
                    />
                  </svg>
                </button>
                <button
                  @click="setConfirmation('deleteTransitionConfirm', transition.id)"
                  class="hover:cursor-pointer mt-3 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg bg-secondary text-primary dark:bg-white/5"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    class="fill-gray-700 stroke-gray-700 hover:fill-white hover:stroke-white"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z"
                      fill=""
                      stroke=""
                    />
                  </svg>
                </button>
                <button
                  @click="
                    setTransitionId(transition.id);
                    state.isTransition = true;
                    state.isState = false;
                  "
                >
                  <p
                    class="py-5 ml-2 text-left leading-relaxed text-gray-800 dark:text-dark-6 hover:underline"
                    :class="{ 'underline font-bold': activeText === transition.id }"
                  >
                    Átmenet: {{ transition.actionName }} - Cél: {{ transition.toStateName }}
                  </p>
                </button>
              </div>
            </div>
          </template>
        </div>
        <div class="w-full px-4 lg:w-2/3" v-if="transitionName || stateName">
          <RemFormCard v-if="state.isTransition">
            <template #remcardheader>
              <h2 class="text-xl text-secondary font-bold mb-8">
                {{ transitionName }}: Custom fields
              </h2>
              <RemPrimaryButton
                :method="
                  () => {
                    connectCustomField();
                  }
                "
                :label="$t('message.assignNew')"
                class="mb-8"
              >
              </RemPrimaryButton>
            </template>
            <template #remcardmain>
              <RobberVueGrid
                ref="wfAdminTransitionGrid"
                :config="wfAdminTransitionGridConfigComputed"
              />
            </template>
          </RemFormCard>

          <RemFormCard v-if="state.isState">
            <template #remcardheader>
              <h2 class="text-xl text-secondary w-full font-bold mb-8">
                {{ stateName }} - custom fields
              </h2>
            </template>
            <template #remcardmain>
              <RobberVueGrid ref="wfAdminStateGrid" :config="wfAdminStateGridConfigComputed" />
            </template>
          </RemFormCard>
        </div>
      </div>
    </div>

    <div class="absolute bottom-0 right-0 z-[-1]">
      <svg
        width="1440"
        height="886"
        viewBox="0 0 1440 886"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M193.307 -273.321L1480.87 1014.24L1121.85 1373.26C1121.85 1373.26 731.745 983.231 478.513 729.927C225.976 477.317 -165.714 85.6993 -165.714 85.6993L193.307 -273.321Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="1308.65"
            y1="1142.58"
            x2="602.827"
            y2="-418.681"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#3056D3" stop-opacity="0.36" />
            <stop offset="1" stop-color="#F5F2FD" stop-opacity="0" />
            <stop offset="1" stop-color="#F5F2FD" stop-opacity="0.096144" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  </section>
  <AddStateModal
    @refresh="getStates()"
    :showModal="modalStatus"
    :stateById="state.stateById"
    :editMode="state.editMode"
  />
  <AddTransitionModal
    @refresh="getTransitions()"
    :states="state.states"
    :showModal="trModalStatus"
    :fromStateId="state.addTransitionModalId"
    :transitionById="state.transitionById"
    :editMode="state.trEditMode"
  />

  <ConnectCustomFieldModal
    v-if="transitionId"
    :showModal="trCfModalStatus"
    :id="transitionId"
    :taskTypeId="taskTypeId"
    :workflowId="workflowId"
    :trCustomFieldsGrid="wfAdminTransitionGrid"
  />

  <!--CONFIRMATION MODAL-->
  <input type="checkbox" class="modal-toggle" v-model="state.confirmationModal.show" />
  <div class="modal">
    <div class="modal-box">
      <h3 class="text-lg text-left font-normal hover:text-gray-100 text-gray-100">
        {{ $t('message.' + state.confirmationModal.message) }}
      </h3>
      <div class="modal-action">
        <label
          class="btn btn-sm text-white font-light text-xs"
          @click="
            () => {
              state.confirmationModal.confirmed = false;
              state.confirmationModal.show = false;
            }
          "
          >{{ $t('message.no') }}</label
        >
        <label
          class="btn btn-sm bg-magenta text-white font-light text-xs"
          @click="
            () => {
              state.confirmationModal.confirmMethod();
            }
          "
          >{{ $t('message.yes') }}</label
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
// import { computedAsync } from '@vueuse/core'
import RobberVueGrid from '../../components/RVG/RobberVueGrid.vue';
import wfAdminTransitionGridConfig from '../../misc/gridConfigs/workflowAdmin/wfAdminTransitionGridConfig';
import wfAdminStateGridConfig from '../../misc/gridConfigs/workflowAdmin/wfAdminStateGridConfig';
import { useI18n } from 'vue-i18n';
import RemFormCard from '../../components/REM/RemFormCard.vue';
import RemPrimaryButton from '../../components/REM/RemPrimaryButton.vue';
import { useRoute } from 'vue-router';
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import axios from '@/misc/axios.js';
import route from '@/misc/router.js';
import store from '@/misc/vuex-store.js';
import AddStateModal from './AddStateModal.vue';
import AddTransitionModal from './AddTransitionModal.vue';
import toast from '@/misc/toast';
import ConnectCustomFieldModal from './ConnectCustomFieldModal.vue';

const router = useRoute();
const taskTypeId = router.params.taskTypeId;
const workflowId = router.params.workflowId;
const stateId = ref(router.params.stateId);
const transitionId = ref(router.params.transitionId);

const wfAdminTransitionGrid = ref();
const wfAdminStateGrid = ref();

const { t } = useI18n();

const state = ref({
  modalType: null,
  states: [],
  transitions: [],
  isState: false,
  isTransition: false,
  addTransitionModalId: null,
  stateById: {},
  transitionById: null,
  editMode: null,
  trEditMode: null,
  confirmationModal: {
    confirmMethod: () => {},
    show: false,
    message: '',
  },
});

const stateName = ref(null);
const transitionName = ref(null);
const filteredTransitions = ref(null);

const setTransitionId = (id) => {
  route.push({ name: 'workflow-transitions', params: { transitionId: id } });
  transitionId.value = id;
  activeText.value = activeText.value === id ? null : id;
  state.value.isState = false;
  state.value.isTransition = true;
};

const setStateId = (id) => {
  route.push({ name: 'workflow-states', params: { stateId: id } });
  stateId.value = id;
  state.value.isState = true;
  state.value.isTransition = false;
};

const setStateDisplayable = async (id, displayable, editable) => {
  const uri =
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
    `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/state/${stateId.value}/custom-field-config/${id}`;

  const hermes = {
    displayable: displayable,
    editable: editable,
  };

  try {
    await axios.put(uri, hermes, {
      onTokenRefreshed: () => {
        setStateDisplayable(id, displayable, editable);
      },
    });
    toast.success(t('message.successfulSave'));
    wfAdminStateGrid.value.refresh();
  } catch (error) {
    console.log(error);
    toast.error(t('message.notSuccessfulSave'));
  }
};

const setTransRequired = async (id, required) => {
  const uri =
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
    `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/transition/${transitionId.value}/custom-field-config/${id}`;

  const hermes = {
    required: required,
  };

  try {
    await axios.put(uri, hermes, {
      onTokenRefreshed: () => {
        setTransRequired(id, required);
      },
    });
    toast.success(t('message.successfulSave'));
    wfAdminTransitionGrid.value.refresh();
  } catch (error) {
    console.log(error);
    toast.error(t('message.notSuccessfulSave'));
  }
};

const transitions = computed(() => {
  const tr = state.value.transitions.filter(
    (transition) => transition.fromStateId == stateId.value
  );
  return tr;
});

const getStates = async () => {
  const uri =
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
    `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/states`;

  try {
    const response = await axios.get(uri, {
      onTokenRefreshed: () => {
        getStates();
      },
    });
    state.value.states = response.data;
    const filteredValue = state.value.states.filter((element) => element.id == stateId.value)[0];
    stateName.value = filteredValue ? filteredValue.name : '';
    const filteredTr = state.value.transitions.filter(
      (transition) => transition.fromStateId == stateId.value
    );
    filteredTransitions.value = filteredTr;
  } catch (error) {
    console.log(error);
  }
};

const getTransitions = async () => {
  const uri =
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
    `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/transitions`;

  try {
    const response = await axios.get(uri, {
      onTokenRefreshed: () => {
        getTransitions();
      },
    });
    state.value.transitions = response.data;

    const filteredValue = state.value.transitions.filter(
      (element) => element.id == transitionId.value
    )[0];
    transitionName.value = filteredValue
      ? 'Átmenet: ' + filteredValue.actionName + ' - Cél: ' + filteredValue.toStateName
      : '';
  } catch (error) {
    console.log(error);
  }
};

const activeState = ref(null);
const activeText = ref(null);

const handleToggle = (id) => {
  stateId.value = id;
  activeState.value = activeState.value === id ? null : id;
};

const addNewState = () => {
  state.value.editMode = false;
  store.commit('setIsModalOpen', true);
};

const setConfirmation = (message, deleteId) => {
  console.log(message, deleteId);

  state.value.confirmationModal.message = message;
  state.value.confirmationModal.show = true;
  state.value.confirmationModal.confirmMethod = () => {
    state.value.confirmationModal.show = false;
    if (message == 'deleteStateConfirm') {
      deleteState(deleteId);
    } else if (message == 'deleteTransitionConfirm') {
      deleteTransition(deleteId);
    }
  };
};

const deleteState = async (id) => {
  const uri =
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
    `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/state/${id}`;
  try {
    await axios.delete(uri, {
      onTokenRefreshed: () => {
        deleteState(id);
      },
    });
    toast.success(t('message.deleteSuccessful'));
    getStates();
    getTransitions();
    state.value.isState = false;
    state.value.isTransition = false;
  } catch (error) {
    console.log(error);
    toast.error(t('message.deleteNotSuccessful'));
  }
};

const deleteTransition = async (id) => {
  const uri =
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
    `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/transition/${id}`;
  try {
    await axios.delete(uri, {
      onTokenRefreshed: () => {
        deleteTransition(id);
      },
    });
    toast.success(t('message.deleteSuccessful'));
    getTransitions();
    state.value.isState = false;
    state.value.isTransition = false;
  } catch (error) {
    console.log(error);
    toast.error(t('message.deleteNotSuccessful'));
  }
};

const addNewTransition = (id) => {
  state.value.addTransitionModalId = id;
  state.value.trEditMode = false;
  store.commit('setIsTrModalOpen', true);
};

const connectCustomField = () => {
  store.commit('setIsTrCfModalOpen', true);
};

let wfAdminTransitionGridConfigComputed = computed(() => {
  return wfAdminTransitionGridConfig(
    state,
    t,
    taskTypeId,
    workflowId,
    transitionId.value,
    wfAdminTransitionGrid,
    setTransRequired
  );
});

let wfAdminStateGridConfigComputed = computed(() => {
  return wfAdminStateGridConfig(
    state,
    t,
    taskTypeId,
    workflowId,
    stateId.value,
    setStateDisplayable
  );
});

watch(
  () => stateId.value,
  () => {
    const filteredTr = state.value.transitions.filter(
      (transition) => transition.fromStateId == stateId.value
    );
    filteredTransitions.value = filteredTr;

    const filteredValue = state.value.states.filter((element) => element.id == stateId.value)[0];
    stateName.value = filteredValue ? filteredValue.name : '';

    wfAdminStateGridConfigComputed = computed(() => {
      return wfAdminStateGridConfig(
        state,
        t,
        taskTypeId,
        workflowId,
        stateId.value,
        setStateDisplayable
      );
    });
  }
);

watch(
  () => transitionId.value,
  () => {
    const filteredValue = state.value.transitions.filter(
      (element) => element.id == transitionId.value
    )[0];
    transitionName.value = filteredValue
      ? filteredValue.actionName + ' > ' + filteredValue.toStateName
      : '';

    wfAdminTransitionGridConfigComputed = computed(() => {
      return wfAdminTransitionGridConfig(
        state,
        t,
        taskTypeId,
        workflowId,
        transitionId.value,
        wfAdminTransitionGrid,
        setTransRequired
      );
    });
  }
);

const editState = async (id) => {
  const uri =
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
    `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/state/${id}`;

  try {
    const response = await axios.get(uri, {
      onTokenRefreshed: () => {
        editState(id);
      },
    });
    state.value.stateById = response.data;
    store.commit('setIsModalOpen', true);
    state.value.editMode = true;
  } catch (error) {
    console.log(error);
  }
};

const editTransition = async (id) => {
  const uri =
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
    `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/transition/${id}`;

  try {
    const response = await axios.get(uri, {
      onTokenRefreshed: () => {
        editTransition(id);
      },
    });
    state.value.transitionById = response.data;
    store.commit('setIsTrModalOpen', true);
    state.value.trEditMode = true;

    console.log(state.value.transitionById);
  } catch (error) {
    console.log(error);
  }
};

const modalStatus = computed(() => {
  return store.getters.getIsModalOpen;
});

const trModalStatus = computed(() => {
  return store.getters.getIsTrModalOpen;
});

const trCfModalStatus = computed(() => {
  return store.getters.getIsTrCfModalOpen;
});

onMounted(() => {
  getStates();
  getTransitions();

  state.value.isState = stateId.value ? true : false;
  state.value.isTransition = transitionId.value ? true : false;
});
</script>

<style scoped>
.bg {
  background-color: hsl(220, 17.647%, 20%);
}
</style>
