import TenantConfigHandler from '../../TenantConfigHandler';

export default {
  data: [
    /// Header

    {
      value: '',
      title: 'taskType',
      valueLabel: '',
      model: 'type',
      type: 'optionWeirdApi',
      mandatory: true,
      validated: false,
      endPoint: TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/rem/workflow/task/type',
      viewList: ['read', 'new', 'newfromlist', 'edit'],
      stateList: [],
      readonlyViewList: ['edit'],
      readonlyStateList: [],
      wfWidth: 3,
    },
    {
      value: '',
      title: 'identifier',
      model: 'identifier',
      type: 'text',
      defaultReadonly: true,
      viewList: ['read', 'edit'],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
      wfWidth: 3,
    },
    {
      value: '',
      title: 'subject',
      model: 'subject',
      type: 'text',
      mandatory: true,
      viewList: ['read', 'new', 'newfromlist', 'edit'],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
      wfWidth: 3,
    },
    // Main
    {
      value: '',
      title: 'description',
      model: 'note',
      type: 'textarea',
      viewList: ['read', 'new', 'newfromlist', 'edit'],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
      wfWidth: 3,
    },

    //Details
    {
      value: '',
      title: 'state',
      valueLabel: '',
      model: 'state',
      type: 'transition',
      viewList: ['read', 'edit'],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
    },
    {
      value: '',
      title: 'responsible',
      model: 'responsible',
      optionSearchEndpoint: 'uploader',
      type: 'optionSearchEndpoint',
      viewList: ['read', 'new', 'newfromlist', 'edit'],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
    },
    {
      value: 'MEDIUM',
      title: 'priority',
      model: 'priority',
      type: 'optionManual',
      mandatory: true,
      validated: false,
      manualOptions: [
        {
          value: 'LOW',
          label: 'PriorityLow',
        },
        {
          value: 'MEDIUM',
          label: 'PriorityMedium',
        },
        {
          value: 'HIGH',
          label: 'PriorityHigh',
        },
      ],
      viewList: ['read', 'new', 'newfromlist', 'edit'],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
    },

    {
      value: '',
      title: 'taskCreatedAt',
      model: 'createdAt',
      type: 'datetime-local',
      defaultReadonly: true,
      viewList: ['read'],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
    },
    {
      value: '',
      title: 'createdBy',
      model: 'createdBy',
      optionSearchEndpoint: 'uploader',
      type: 'optionSearchEndpoint',
      defaultReadonly: true,
      viewList: ['read'],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
    },
    {
      value: '',
      title: 'deadline',
      model: 'deadline',
      type: 'date',
      viewList: ['read', 'new', 'newfromlist', 'edit'],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],

      validationMethod: (
        options,
        optionsRepo,
        value,
        newValue,
        oldValue,
        inputValue,
        inputError,
        inputErrorVariables,
        route
      ) => {
        let d = null;
        if (route.params.taskId == 0) {
          d = new Date().setHours(0, 0, 0, 0);
        } else {
          d = new Date(value.value).setHours(0, 0, 0, 0);
        }

        if (!newValue) {
          inputError.value = '';
          return;
        }

        let newD = new Date(newValue).setHours(0, 0, 0, 0);

        if (d <= newD) {
          inputError.value = '';
          //dateCantBeEarlierThan
        } else {
          if (route.params.taskId == 0) {
            inputError.value = 'message.dateCantBeEarlierThan';
            d = new Date(d);
            inputErrorVariables.value = [d.toLocaleDateString()];
          } else {
            inputError.value = 'message.earlierThenCreationError';
            d = new Date(d);
            inputErrorVariables.value = [d.toLocaleDateString()];
          }
        }
      },
    },

    // not known yet
    {
      value: '',
      title: 'updatedAt',
      model: 'updatedAt',
      type: 'datetime-local',
      defaultReadonly: true,
      viewList: [],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
    },
    {
      value: '',
      title: 'approvedAt',
      model: 'approvedAt',
      type: 'datetime-local',
      defaultReadonly: true,
      viewList: [],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
    },
    {
      value: '',
      title: 'secondaryApprovedAt',
      model: 'secondaryApprovedAt',
      type: 'datetime-local',
      defaultReadonly: true,
      viewList: ['read'],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
    },
    {
      value: '',
      title: 'resolvedAt',
      model: 'resolvedAt',
      type: 'datetime-local',
      defaultReadonly: true,
      viewList: [],
      stateList: [],
      readonlyViewList: [],
      readonlyStateList: [],
    },
  ],
};
