<!------------
README

<RemFormInput 
v-model:inputValue="VARIABLE FOR TWO WAY BINDING WITH THE INPUT VALUE" 
v-model:parent="change this variable to trigger onchange method"
v-model:validated="signals if the field is validated"

config:{

type="THE TYPE OF THE INPUT FIELD EG. TEXT, NUMBER, EMAIL
    UNIQUE VERSION ARE 
        LABEL - FORM SECTION LABEL WITH HORIZONTAL RULE
        CHECKBOX - DISPLAYED AS A YES-NO SELECT
        OPTION - FETCHES AZ OPTIONVALUE
            USE THE OPTIONVALUE FIELD TO DEFINE THE OPTIONVALUE TYPE
        OPTIONAPI - FETCHES VALUES FROM A DEDICATED ENDPOINT
            USE THE ENDPOINT FIELD TO DEFINE THE ENDPOINT 
            THE VALUE OF THE OPTIONS WILL BE THE RECORD ID
            USE THE DISPLAYVALUE FIELD TO DEFINE THE OPTIONS LABEL FIELD" 
        OPTIONMANUAL - GIVE THE OPTIONS IN AN ARRAY TROUGH THE manualOptions PROPERTY
        OPTIONSEARCHENDPOINT - USES THE OptionSearchEndpoint COMPONENT
        optionMultiSelect - 

title="LABEL OF THE INPUT FIELD" 
manualOptions="LIST OF THE OPTIONS FOR OPTIONMANUAL TYPE" 
    format: [
        {
            label: LABEL_OF_OPTION,
            value: VALUE_OF_OPTION,
        },
        {
            label: LABEL_OF_OPTION,
            value: VALUE_OF_OPTION,
        }
    ]

mandatory="is the field mandatory"

valueLabel="LABEL OF THE INITIAL VALUE"
searchable="SEARCH BETWEEN THE OPTIONS (OPTION AND OPTIONAPi ONLY)"

searchable="TRUE / FALSE - ENABLES SEARCHING IN THE OPTIONS"

optionValue="OPTIONVALUE TO FETCH THE OPTIONS FROM E.G. DOCUMENT_TYPE (ONLY FOR OPTIONVALUE)"   

endPoint="ENDPOINT TO FETCH THE OPTIONS FROM" 
displayValue="WHICH FIELD SHOULD BE THE LABEL OF THE OPTION (ONLY FOR OPTIONAPI)"

onchangeMethod= (options, optionsRepo, value, newValue, oldValue, inputValue, inputError) {}
reacts to v-model:parent change

validationMethod= (options, optionsRepo, value, newValue, oldValue, inputValue, inputError, inputErrorVariables) {}
reacts to inputValue change

}
>
</RemFormInput>


-->

<template>
    <div>

        <span v-if="inputError != ''" class="label-text-alt text-red-400">{{ $t(inputError, inputErrorVariables)
        }}</span>
        <!-- eslint-disable -->
        <StandardInput v-if="!notStandardInputs.includes(config.type)" :type="config.type" :title="config.title"
            v-model:inputValue="inputValue" v-model:focus="focus" :disabled="disabled"></StandardInput>


        <div v-if="config.type == 'optionSearchEndpoint'">
            <SearchOptions :type="config.optionSearchEndpoint" :buildingId="config.buildingId"
                v-model:inputValue="inputValue" v-model:focus="focus" :focusId="`${config.title}${config.type}`"
                :valueLabel="config.valueLabel" />
        </div>

        <div v-if="config.type == 'currencyField'">
            <CurrencyField :type="config.type" :title="config.title" v-model:inputValue="inputValue"
                v-model:focus="focus" :disabled="disabled" v-model:optionsOut="options"
                :optionValue="config.optionValue" :onchangeMethod="config.onchangeMethod"
                :validationMethod="config.validationMethod" :mandatory="config.mandatory"
                v-model:inputError="inputError"></CurrencyField>
        </div>

        <div
            v-if="config.type == 'option' || config.type == 'optionapi' || config.type == 'optionManual' || config.type == 'optionWeirdApi'">
            <RemOption v-model:inputValue="inputValue" v-model:parent="parent" v-model:validated="validated"
                v-model:inputError="inputError" v-model:focus="focus" v-model:optionsOut="options" :type="config.type"
                :valueLabel="config.valueLabel" :optionValue="config.optionValue" :endPoint="config.endPoint"
                :manualOptions="config.manualOptions" :displayValue="config.displayValue"
                :searchable="config.searchable" :onchangeMethod="config.onchangeMethod"
                :validationMethod="config.validationMethod" :mandatory="config.mandatory" :title="config.title"
                :disabled="disabled">
            </RemOption>
        </div>

        <div v-if="config.type == 'optionMultiSelect'">
            <RemMultiSelect v-model:inputValue="inputValue" v-model:focus="focus" v-model:optionsOut="options"
                :type="config.type" :valueLabel="config.valueLabel" :optionValue="config.optionValue"
                :endPoint="config.endPoint" :manualOptions="config.manualOptions" :displayValue="config.displayValue"
                :searchable="config.searchable" :onchangeMethod="config.onchangeMethod"
                :validationMethod="config.validationMethod" :mandatory="config.mandatory" :title="config.title">
            </RemMultiSelect>
        </div>

        <div v-if="config.type == 'number'">
            <RemNumberInput :title="config.title" v-model:inputValue="inputValue" v-model:focus="focus"
                v-model:inputError="inputError" v-model:inputErrorVariables="inputErrorVariables"
                :validationMethod="config.validationMethod"></RemNumberInput>
        </div>

        <div v-if="config.type == 'positiveNumber'">
            <RemPositiveNumberInput :title="config.title" v-model:inputValue="inputValue" v-model:focus="focus"
                v-model:inputError="inputError" v-model:inputErrorVariables="inputErrorVariables"
                :validationMethod="config.validationMethod"></RemPositiveNumberInput>
        </div>

        <div v-if="config.type == 'integer'">
            <RemIntegerInput :title="config.title" v-model:inputValue="inputValue" v-model:focus="focus"
                v-model:inputError="inputError" v-model:inputErrorVariables="inputErrorVariables"
                :validationMethod="config.validationMethod"></RemIntegerInput>
        </div>

        <RemCheckBox v-if="config.type == 'checkbox'" :type="config.type" v-model:inputValue="inputValue"
            v-model:focus="focus" :title="config.title"></RemCheckBox>

        <input v-if="config.type == 'toggle'" v-model="inputValue" type="checkbox" class="toggle toggle-secondary"
            checked="checked" @focusout="focus = false" :id="`${config.title}${config.type}`" />
        <textarea class="w-full p-4 input input-sm input-bordered w-full h-40" v-if="config.type == 'textarea'"
            :id="`${config.title}${config.type}`" v-model="inputValue" @focusout="focus = false"></textarea>
        <!-- eslint-enable -->
    </div>
</template>

<script setup>
import StandardInput from './StandardInput.vue';
import RemCheckBox from './RemCheckBox.vue';
import RemOption from './RemOption.vue';
import RemNumberInput from './RemNumberInput.vue';
import RemPositiveNumberInput from './RemPositiveNumberInput.vue';
import SearchOptions from '../../SearchOptions.vue';
import RemMultiSelect from './RemMultiSelect.vue';
import CurrencyField from './CurrencyField.vue';
import RemIntegerInput from './RemIntegerInput.vue';

import { ref, defineModel, watch } from 'vue';
import { useRoute } from "vue-router";
let route = useRoute();



const props = defineProps(['config', 'disabled']);
const inputValue = defineModel('inputValue');
const parent = defineModel('parent')
const validated = defineModel('validated')
const focus = defineModel('focus')
const options = defineModel('options')


const inputError = ref("");
const inputErrorVariables = ref([]);

const notStandardInputs = ['option', 'optionapi', 'checkbox', 'label', 'toggle', 'optionManual', 'optionSearchEndpoint', 'textarea', 'number', 'positiveNumber', 'integer', 'optionWeirdApi', 'optionMultiSelect', 'currencyField']



if (props.config.type != 'option' && props.config.type != 'optionapi' && props.config.type != 'optionManual' && props.config.type != 'optionWeirdApi' && props.config.type != 'currencyField') {
    watch(() => inputValue.value, (newValue, oldValue) => {

        validate(newValue, oldValue)

    })
}





const validate = (newValue, oldValue) => {

    let lever = true;
    if (props.config.mandatory && (newValue == "" || newValue == null || newValue == 'null')) {
        validated.value = false;
        return;
    }



    if (props.config.validationMethod && newValue != '' && newValue != oldValue) {

        props.config.validationMethod([], [], parent, newValue, oldValue, inputValue, inputError, inputErrorVariables, route)
        if (inputError.value != "") {
            lever = false;
        }
    }



    validated.value = lever;


}

validate(inputValue.value, null)

watch(() => props.config.mandatory,
    () => {
        validate(inputValue.value, null)
    })

</script>
