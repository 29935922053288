<template>
    <div>
        <!-- Modal toggle -->
        <input type="checkbox" class="modal-toggle" v-model="state.showModal" />
        <div class="modal">
            <div class="modal-box">
                <div class="h-full overflow-y-auto w-full">
                    <div class="mt-5 mx-5 flex flex-row grow border-b-0 border-base-300 justify-between">
                        <div class="flex flex-row items-center gap-2 justify-center">
                            <h2
                                class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow">
                                <span class="text-secondary">{{
                                    !props.editMode ? $t("message.addNew") : $t("message.edit")
                                    }}</span>
                            </h2>
                        </div>
                        <a href="javascript:void(null)" class="hover:text-secondary" @click="closeModal">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </a>
                    </div>

                    <div class="px-5 pb-5">
                        <div class="flex flex-col items-center justify-center gap-2 w-full">
                            <div class="flex flex-row gap-3 w-full mt-2">
                                <!--eslint-disable-->
                                <ImFormInput v-model:inputValue="state.name" :title="$t('message.name')" 
                                type="text" class="w-full mr-4">
                                </ImFormInput>
                            </div>

                            <div class="flex flex-row gap-3 w-full mt-2">
                                
                                <ImFormInput v-model:inputValue="state.startState" :title="$t('message.startState')" 
                                type="checkbox" class="w-full mr-4">
                                </ImFormInput>
                            </div>
                            <div class="flex flex-row gap-3 w-full mt-2">
                                <ImFormInput v-model:inputValue="state.endState" :title="$t('message.endState')" 
                                type="checkbox" class="w-full mr-4">
                                </ImFormInput>
                            </div>
                            <div class="flex flex-row gap-3 w-full mt-2">
                                <ImFormInput v-model:inputValue="state.code" :title="$t('message.code')" 
                                type="text" class="w-full mr-4">
                                </ImFormInput>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-action">
                    <label class="btn btn-sm bg-magenta text-white font-light text-xs" @click="() => {
                        saveState();
                        this.$emit('refresh')
                        store.commit('setIsModalOpen', false);
                    }
                        ">{{ $t("message.save") }}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { /*computed,*/ onMounted, reactive, watch } from "vue";
import Axios from "@/misc/axios";
import toast from "@/misc/toast.js";
import store from "@/misc/vuex-store.js";
import { useI18n } from "vue-i18n";
import TenantConfigHandler from "@/misc/TenantConfigHandler";
import { useRoute } from "vue-router";
import ImFormInput from "@/components/ImFormInput.vue";

const { t } = useI18n();
const router = useRoute();
const taskTypeId = router.params.taskTypeId
const workflowId = router.params.workflowId

const emits = defineEmits(['refresh'])

const state = reactive({
    showModal: false,
    name: null,
    startState: null,
    endState: null,
    code: null,
});

const props = defineProps(["showModal", "stateById", "editMode"]);

const saveState = async () => {
    let uri = ''
    const hermes = {
        name: state.name,
        startState: state.startState,
        endState: state.endState,
        code: state.code
    }

    if (!props.editMode) {
        uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/states`

        try {
            await Axios.post(uri, hermes, {
                onTokenRefreshed: () => {
                    saveState()
                }
            })

            toast.success(t("message.successfulSave"))
            emptyForm()
            emits('refresh')
        } catch (error) {
            console.log(error);
            toast.error(t("message.notSuccessfulSave"))
        }
    }else if (props.editMode) {
    uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/state/${props.stateById.id}`

    try {
      await Axios.put(uri, hermes, {
        onTokenRefreshed: () => {
          saveState()
        }
      })

      toast.success(t("message.successfulSave"))
      emptyForm()
      emits('refresh')
    } catch (error) {
      console.log(error);
      toast.error(t("message.notSuccessfulSave"))
    }
  }
}
const fillForm = () => {
    state.name = props.stateById.name,
    state.startState = props.stateById.startState,
    state.endState = props.stateById.endState,
    state.code = props.stateById.code
}

watch(
    () => props.showModal,
    () => {
        state.showModal = props.showModal;
        emits('refresh')
        if(state.showModal && props.editMode){
            fillForm()
        }else{
            emptyForm()
        }
    }
);

const closeModal = () => {
  store.commit('setIsModalOpen', false);
}

const emptyForm = () => {
    state.name = null,
    state.startState = null,
    state.endState = null,
    state.code = null
}

onMounted(() => { });
</script>

<style scoped></style>