<template>
  <div id="BuildingPage" class="h-screen">
    <BaseLayout>
      <template #mainContent>
        <RemFormLayout>
          <template #remformheader>
            <div class="items-start pb-6 mx-auto md:flex-row mb-4">
              <div class="flex justify-between mb-4">
                <h1 class="flex mb text-xl text-secondary md:mb-0 uppercase font-bold">
                  {{ $t('message.premise') }} - {{ state.mainPremise.basicDataFloorValue }} -
                  {{ state.mainPremise.basicDataPremiseFunctionValue }} -
                  {{ state.mainPremise.basicDataIdentifier }} -
                  {{ state.building.basicDataTcafm }} ({{
                    state.property ? state.property.primaryAddressCompleteAddress : ''
                  }})
                </h1>

                <RemUpButton remType="premise" />
              </div>
            </div>
          </template>
          <template #remformsidemenu>
            <!-- BASICDATA -->
            <div class="flex-row mb-4">
              <SideMenuButton
                :onClick="
                  () => {
                    setTab(1, 'premise-data');
                    setRoute('premise-data');
                  }
                "
                :activeCheck="router.name == 'premise-data'"
                :icon="'data_icon'"
                :buttonLabel="'data'"
              ></SideMenuButton>
            </div>

            <!-- LEGAL SITUATION -->
            <div class="flex-row mb-4">
              <SideMenuButton
                :onClick="
                  () => {
                    setTab(2, 'premise-legal-situations');
                    setRoute('premise-legal-situations');
                  }
                "
                :activeCheck="router.name == 'premise-legal-situations'"
                :icon="'legal_icon'"
                :buttonLabel="'legalSituations'"
              ></SideMenuButton>
            </div>

            <!-- UTILIZATION -->
            <div class="flex-row mb-4">
              <SideMenuButton
                :onClick="
                  () => {
                    setTab(3, 'premise-utilizations');
                    setRoute('premise-utilizations');
                  }
                "
                :activeCheck="router.name == 'premise-utilizations'"
                :icon="'system_settings_icon'"
                :buttonLabel="'utilizations'"
              ></SideMenuButton>
            </div>

            <!-- METERS -->
            <div class="flex-row mb-4">
              <SideMenuButton
                :onClick="
                  () => {
                    setTab(11, 'premise-meters');
                    setRoute('premise-meters');
                  }
                "
                :activeCheck="router.name == 'premise-meters'"
                :icon="'meter_icon'"
                :buttonLabel="'meters'"
              ></SideMenuButton>
            </div>

            <!-- SPECIAL DATA -->
            <div class="flex-row mb-4">
              <SideMenuButton
                :onClick="
                  () => {
                    setTab(4, 'special-data');
                    setRoute('special-data');
                  }
                "
                :activeCheck="router.name == 'special-data'"
                :icon="'obli_icon'"
                :buttonLabel="'specialData'"
              ></SideMenuButton>
            </div>

            <!-- COOLING HEATING DEVICES -->
            <div class="flex-row mb-4">
              <SideMenuButton
                :onClick="
                  () => {
                    setTab(13, 'cooling-heating-devices');
                    setRoute('cooling-heating-devices');
                  }
                "
                :activeCheck="router.name == 'cooling-heating-devices'"
                :icon="'wind_icon'"
                :buttonLabel="'coolingHeatingDevices'"
              ></SideMenuButton>
            </div>

            <!-- DOCUMENTS -->
            <div class="flex-row mb-4">
              <SideMenuButton
                :onClick="
                  () => {
                    setTab(6, 'premise-documents');
                    setRoute('premise-documents');
                  }
                "
                :activeCheck="router.name == 'premise-documents'"
                :icon="'document_icon'"
                :buttonLabel="'documents'"
              ></SideMenuButton>
            </div>

            <!-- PARTNERS -->
            <div class="flex-row mb-4">
              <SideMenuButton
                :onClick="
                  () => {
                    setTab(14, 'premise-partners');
                    setRoute('premise-partners');
                  }
                "
                :activeCheck="router.name == 'premise-partners'"
                :icon="'partner_icon'"
                :buttonLabel="'partners'"
              ></SideMenuButton>
            </div>

            <div v-if="User.hasPermission('WorkflowUser')" class="flex-row mb-4">
              <SideMenuButton
                :onClick="
                  () => {
                    setTab(18, 'premise-linked-tasks');
                    setRoute('premise-linked-tasks');
                  }
                "
                :activeCheck="router.name == 'premise-linked-tasks'"
                :icon="'linked_tasks_icon'"
                :buttonLabel="'relatedTasks'"
              ></SideMenuButton>
            </div>
          </template>
          <template #remformmain>
            <!-------------------------Alapadatok ------------>
            <RemFormCard v-if="router.name == 'premise-data'">
              <template #remcardheader>
                <RemFormBackbutton
                  v-if="store.getters.getRemFormEdit == true"
                  :method="
                    () => {
                      setTab(1);
                    }
                  "
                ></RemFormBackbutton>
                <h2 class="text-xl text-secondary w-full font-bold">
                  {{ $t('message.data') }}
                </h2>
              </template>
              <template #remcardmain>
                <!-- eslint-disable -->
                <PremiseBasicdataForm
                  v-model:saveMe="state.premiseSave"
                  v-model:setMeBack="state.setPremiseBack"
                  v-model:mainPremise="state.mainPremise"
                ></PremiseBasicdataForm>
                <!-- eslint-enable -->
              </template>
            </RemFormCard>

            <!-------------------------Jogi helyzet ------------>

            <!-- eslint-disable -->
            <RemRVGForm
              v-if="
                (router.name == 'premise-legal-situations' && !store.getters.getRemFormEdit) ||
                store.getters.getRemTab == 8
              "
              :title="$t('message.legalSituation')"
              rvgTab="premise-legal-situations"
              formTab="8"
              :addNewMethod="
                () => {
                  addNewTab('legalsit', 8);
                }
              "
              v-model:formData="state.legalsitData"
              :rvgConfig="legalsitGridConfigComputed"
              :saveMethod="saveLegalsit"
              :deleteMethod="deleteLegalSit"
              :backMethod="
                () => {
                  setTab(2);
                }
              "
              v-model:elementId="state.legalsitId"
            >
            </RemRVGForm>
            <!-- eslint-enable -->

            <!------------------ Hasznosítás - Lista ---------------->

            <!-- eslint-disable -->
            <RemRVGForm
              v-if="
                (router.name == 'premise-utilizations' && !store.getters.getRemFormEdit) ||
                store.getters.getRemTab == 7
              "
              :title="$t('message.utilizations')"
              rvgTab="premise-utilizations"
              formTab="7"
              :addNewMethod="
                () => {
                  addNewTab('utilization', 7);
                }
              "
              v-model:formData="state.utilizationData"
              :rvgConfig="utilizationGridConfigComputed"
              :saveMethod="saveUtilization"
              :deleteMethod="deleteUtilization"
              :backMethod="
                () => {
                  setTab(3);
                }
              "
              v-model:elementId="state.utilizationId"
            >
            </RemRVGForm>
            <!-- eslint-enable -->

            <!------------------ Speciális adtaok ---------------->

            <RemFormCard v-if="router.name == 'special-data'">
              <template #remcardheader>
                <RemFormBackbutton
                  v-if="store.getters.getRemFormEdit == true"
                  :method="
                    () => {
                      setTab(4);
                    }
                  "
                ></RemFormBackbutton>
                <h2 class="text-xl text-secondary w-full font-bold">
                  {{ $t('message.specialData') }}
                </h2>
              </template>
              <template #remcardmain>
                <div v-for="item in state.specialData" v-bind:key="item.model" class="mb-8">
                  <div v-if="store.getters.getRemFormEdit == false">
                    <ImFormLabel :item="item"></ImFormLabel>
                  </div>
                  <div v-if="store.getters.getRemFormEdit == true">
                    <!-- eslint-disable -->
                    <ImFormInput
                      v-model:inputValue="item.value"
                      :title="$t('message.' + item.title)"
                      :type="item.type"
                      :valueLabel="item.valueLabel"
                      :optionValue="item.optionValue"
                      :endPoint="item.endPoint"
                      :displayValue="item.displayValue"
                      :searchable="item.searchable"
                      :helperText="item.helperText ? $t('message.' + item.helperText) : null"
                      :validationMethod="item.validationMethod"
                    >
                    </ImFormInput>
                    <!-- eslint-enable -->
                  </div>
                </div>

                <!-- eslint-disable -->
                <RemFormButtonRowStored
                  :saveMethod="
                    () => {
                      savePremiseSpecial();
                    }
                  "
                  deleteMethod=""
                >
                </RemFormButtonRowStored>
                <!-- eslint-enable -->
              </template>
            </RemFormCard>

            <!------------------ Mérőórák ---------------->

            <!-- eslint-disable -->
            <RemRVGForm
              v-if="
                (router.name == 'premise-meters' && !store.getters.getRemFormEdit) ||
                store.getters.getRemTab == 12
              "
              :title="$t('message.meters')"
              rvgTab="premise-meters"
              formTab="12"
              :addNewMethod="
                () => {
                  addNewTab('meter', 12);
                }
              "
              v-model:formData="state.meterData"
              :rvgConfig="meterGridConfigComputed"
              :saveMethod="saveMeter"
              :deleteMethod="deleteMeter"
              :backMethod="
                () => {
                  setTab(11);
                }
              "
              v-model:elementId="state.meterId"
            >
            </RemRVGForm>
            <!-- eslint-enable -->

            <!------------------ coolingHeatingDevice - szerkesztés ---------------->
            <!-- eslint-disable -->
            <RemRVGForm
              v-if="
                (router.name == 'cooling-heating-devices' && !store.getters.getRemFormEdit) ||
                store.getters.getRemTab == 17
              "
              :title="$t('message.coolingHeatingDevices')"
              rvgTab="cooling-heating-devices"
              formTab="17"
              :addNewMethod="
                () => {
                  addNewTab('coolingHeatingDevice', 17);
                }
              "
              v-model:formData="state.coolingHeatingDeviceData"
              :rvgConfig="CHDGridConfigComputed"
              :saveMethod="saveCoolingHeatingDevice"
              :deleteMethod="deleteCoolingHeatingDevice"
              :backMethod="
                () => {
                  setTab(13);
                }
              "
              v-model:elementId="state.coolingHeatingDeviceId"
            >
            </RemRVGForm>
            <!-- eslint-enable -->

            <!-- <RemFormCard v-if="store.getters.getRemTab == 13"> -->
            <!-- <template #remcardheader> -->
            <!-- <RemFormBackbutton v-if="store.getters.getRemFormEdit == true" :method="() => { setTab(13) }"></RemFormBackbutton> -->
            <!-- <h2 class="text-xl text-secondary w-full font-bold"> -->
            <!-- {{ $t("message.coolingHeatingDevice") }} -->
            <!-- </h2> -->
            <!-- </template> -->
            <!-- <template #remcardmain> -->
            <!-- <div v-for="item in state.coolingHeatingDeviceData" v-bind:key="item.model" class="mb-8"> -->
            <!-- <div v-if="store.getters.getRemFormEdit == false"> -->
            <!-- <ImFormLabel :item="item"></ImFormLabel> -->
            <!-- </div> -->
            <!-- <div v-if="store.getters.getRemFormEdit == true"> -->
            <!-- eslint-disable -->
            <!-- <ImFormInput v-model:inputValue="item.value" v-model:parent="item.parent" -->
            <!-- :title="$t('message.' + item.title)" :type="item.type" :valueLabel="item.valueLabel" -->
            <!-- :optionValue="item.optionValue" :endPoint="item.endPoint" :manualOptions="item.manualOptions" -->
            <!-- :displayValue="item.endPoint" :searchable="item.searchable" :onchangeMethod="item.onchangeMethod" -->
            <!-- :validationMethod="item.validationMethod"> -->
            <!-- </ImFormInput> -->
            <!-- eslint-enable -->

            <!-- </div> -->
            <!-- </div> -->

            <!-- eslint-disable -->
            <!-- <RemFormButtonRowStored :saveMethod="() => {saveCoolingHeatingDevice()}" deleteMethod=""></RemFormButtonRowStored> -->
            <!-- eslint-enable -->

            <!-- </template> -->
            <!-- </RemFormCard> -->

            <!------------------------- Partnerek ------------>
            <RemFormCard
              v-if="
                router.name == 'premise-partners' &&
                !store.getters.getRemFormEdit &&
                store.getters.getRemTab != 16
              "
            >
              <template #remcardheader>
                <h2 class="text-xl text-secondary w-full font-bold mb-8">
                  {{ $t('message.partners') }}
                </h2>
                <RemPrimaryButton
                  :method="
                    () => {
                      setTab(16);
                    }
                  "
                  :label="$t('message.assignNew')"
                  class="mb-8"
                >
                </RemPrimaryButton>
              </template>
              <template #remcardmain>
                <RobberVueGrid ref="partnersGrid" :config="partnersGridConfigComputed" />
              </template>
            </RemFormCard>

            <!------------------ Partnerek - szerkesztés ---------------->
            <RemFormCard v-if="store.getters.getRemTab == 15">
              <template #remcardheader>
                <RemFormBackbutton
                  :method="
                    () => {
                      setTab(14);
                    }
                  "
                ></RemFormBackbutton>

                <h2 class="text-xl text-secondary w-full font-bold">
                  {{ $t('message.partner') }}
                </h2>
              </template>
              <template #remcardmain>
                <div v-for="item in state.partnerData" v-bind:key="item.model" class="mb-8">
                  <div v-if="store.getters.getRemFormEdit == false">
                    <!-- eslint-disable -->
                    <RemVisibilityWrapper
                      v-model:toWatch="item.parent"
                      :visibilityCondition="item.visibilityCondition"
                    >
                      <ImFormLabel :item="item"></ImFormLabel>
                    </RemVisibilityWrapper>
                    <!-- eslint-enable -->
                  </div>
                  <div v-if="store.getters.getRemFormEdit == true">
                    <!-- eslint-disable -->
                    <ImFormInputVisibilityWraped
                      v-model:inputValue="item.value"
                      v-model:parent="item.parent"
                      v-model:toWatch="item.parent"
                      v-model:validated="item.validated"
                      :config="item"
                    >
                    </ImFormInputVisibilityWraped>
                    <!-- <ImFormInput v-model:inputValue="item.value"
                      v-model:parent="item.parent" :title="$t('message.' + item.title)" :type="item.type"
                      :valueLabel="item.valueLabel" :optionValue="item.optionValue" :endPoint="item.endPoint"
                      :manualOptions="item.manualOptions" :displayValue="item.endPoint" :searchable="item.searchable"
                      :onchangeMethod="item.onchangeMethod" :validationMethod="item.validationMethod">
                    </ImFormInput> -->
                    <!-- eslint-enable -->
                  </div>
                </div>

                <!-- eslint-disable -->
                <RemFormButtonRowStored
                  :saveMethod="savePartner"
                  :deleteMethod="deletePartner"
                  v-model:elementId="state.partnerId"
                >
                </RemFormButtonRowStored>
                <!-- eslint-enable -->
              </template>
            </RemFormCard>

            <!---------PARTNER-SEARCH----------->
            <RemPartnerAssignSearch
              v-if="store.getters.getRemTab == 16"
              :type="'premise'"
              :backFunc="() => setTab(14)"
              :id="premiseId"
              :createFunc="
                () => {
                  addNewTab('partner', 15);
                }
              "
              :partnerMainTypes="partnerMainTypes"
              :partnerSubTypes="partnerSubTypes"
            >
            </RemPartnerAssignSearch>

            <!------------------ Dokumentumok ---------------->

            <RemFormCard v-if="router.name == 'premise-documents'">
              <template #remcardheader>
                <h2 class="text-xl text-secondary w-full font-bold">
                  {{ $t('message.documents') }}
                </h2>
              </template>
              <template #remcardmain>
                <div class="flex flex-row w-full mb-4">
                  <!-- eslint-disable -->
                  <ImFormInput
                    v-model:inputValue="state.documentSetup.documentName"
                    :title="$t('message.name')"
                    type="text"
                    class="w-full mr-4 th"
                    endPoint=""
                    displayValue=""
                  >
                  </ImFormInput>

                  <div class="form-control w-full">
                    <label class="label mt-0 pt-0" for="">
                      <span class="label-text-alt"
                        >{{ $t('message.dateOfOrigin') }}
                        <span
                          :class="{
                            'im-mandatory':
                              !state.documentSetup.documentDateOfOrigin ||
                              state.documentSetup.documentDateOfOrigin == '',
                          }"
                          >{{ ' *' }}</span
                        >
                      </span>
                    </label>
                    <label class="flex flex-row gap-2">
                      <VueDatePicker
                        format="yyyy MMM dd"
                        :enable-time-picker="false"
                        :time-picker-inline="true"
                        :locale="$i18n.locale"
                        :month-change-on-scroll="false"
                        :action-row="{}"
                        :clearable="true"
                        select-text="OK"
                        :cancel-text="t('message.cancel')"
                        :teleport="true"
                        model-type="yyyy-MM-dd"
                        :placeholder="t('message.choose')"
                        v-model="state.documentSetup.documentDateOfOrigin"
                        dark="true"
                      />
                    </label>
                  </div>
                  <!-- <ImFormInput v-model:inputValue="state.documentSetup.documentDateOfOrigin"
                    :title="$t('message.dateOfOrigin')" type="date" class="w-full ml-4" endPoint="" displayValue="">
                  </ImFormInput> -->
                  <!-- eslint-enable -->
                </div>
                <div class="flex flex-row w-full mb-4">
                  <!-- eslint-disable -->
                  <ImFormInput
                    v-model:inputValue="state.documentSetup.documentType"
                    :title="$t('message.mainType')"
                    type="option"
                    :valueLabel="state.documentSetup.documentTypeLabel"
                    optionValue="DOCUMENT_TYPE"
                    class="w-full mr-4"
                    endPoint=""
                    displayValue=""
                    searchable="true"
                    :mandatory="true"
                    :validated="true"
                  >
                  </ImFormInput>
                  <ImFormInput
                    v-model:inputValue="state.documentSetup.documentSubType"
                    v-model:parent="state.documentSetup.documentSubTypeParent"
                    :title="$t('message.subType')"
                    type="option"
                    :valueLabel="state.documentSetup.documentSubTypeLabel"
                    optionValue="DOCUMENT_SUB_TYPE"
                    class="w-full"
                    endPoint=""
                    displayValue=""
                    :onchangeMethod="
                      (options, optionsRepo, value) => {
                        options.value = optionsRepo.filter((elem) => elem.parent == value);
                      }
                    "
                    searchable="true"
                    :mandatory="true"
                    :validated="true"
                    :disabled="disabled"
                  >
                  </ImFormInput>
                  <ImFormInput
                    v-model:inputValue="state.documentSetup.contractIdentifier"
                    :title="$t('message.contractIdentifier')"
                    type="text"
                    class="w-full ml-4"
                    endPoint=""
                    displayValue=""
                    :disabled="disabledContractId"
                  >
                  </ImFormInput>
                  <!-- eslint-enable -->
                </div>
                <input
                  class="file:p-2 file:bg-gray-800 file:border-none file:hover:cursor-pointer block w-full text-sm text-gray-200 border border-gray-300 rounded-md bg-gray-50 dark:text-gray-200 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 mb-8"
                  aria-describedby="file_input_help"
                  :accept="allowedExtensions"
                  id="file_input"
                  type="file"
                  ref="documentFile"
                />

                <button
                  @click="uploadFile"
                  class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8"
                >
                  {{ $t('message.upload') }}
                </button>

                <hr class="mb-8" />
                <RobberVueGrid ref="documentsGrid" :config="documentsGridConfigComputed" />

                <input
                  type="checkbox"
                  id="document_preview"
                  class="modal-toggle"
                  v-model="state.documentPreview.open"
                />
                <div class="modal" role="dialog">
                  <div class="modal-box im-preview-modal-box">
                    <h3 class="text-lg font-bold mb-4">{{ state.documentPreview.title }}</h3>
                    <iframe
                      class="w-full"
                      style="height: 80%"
                      :src="state.documentPreview.url"
                    ></iframe>
                    <div class="modal-action">
                      <label for="document_preview" class="btn"> {{ $t('message.close') }}</label>
                    </div>
                  </div>
                </div>
              </template>
            </RemFormCard>

            <input type="checkbox" class="modal-toggle" v-model="state.showConfirmation" />
            <div class="modal">
              <div class="modal-box">
                <h3 class="text-lg text-left font-normal hover:text-gray-100 text-gray-100">
                  {{ $t('message.deleteDocumentConfirm') }}
                </h3>
                <div class="modal-action">
                  <label
                    class="btn btn-sm text-white font-light text-xs"
                    @click="
                      () => {
                        state.showConfirmation = false;
                      }
                    "
                    >{{ $t('message.deny') }}</label
                  >
                  <label
                    class="btn btn-sm bg-magenta text-white font-light text-xs"
                    @click="
                      () => {
                        deleteDocument(state.docuIdForEdit);
                        state.showConfirmation = false;
                      }
                    "
                    >{{ $t('message.confirm') }}</label
                  >
                </div>
              </div>
            </div>

            <EditDocumentModal
              :showModal="modalStatus"
              :id="state.docuIdForEdit"
              :premiseGrid="documentsGrid"
            />
            <!-- eslint-disable -->

            <!--LINKED TASKS-->
            <RemRVGForm
              v-if="
                (router.name == 'premise-linked-tasks' && !store.getters.getRemFormEdit) && User.hasPermission('WorkflowUser') ||
                store.getters.getRemTab == 18 && User.hasPermission('WorkflowUser')
              "
              :title="$t('message.linkedTasksOverview')"
              rvgTab="premise-linked-tasks"
              :rvgConfig="linkedTasksGridConfigComputed"
              :hideButton = true
            >
            </RemRVGForm>

            <RemFormConfirmationModal
              v-model:showConfirmation="state.confirmation.showConfirmation"
              v-model:confirmationTextToken="state.confirmation.confirmationTextToken"
              v-model:confirmationTrueMethod="state.confirmation.confirmationTrueMethod"
              v-model:confirmationFalseMethod="state.confirmation.confirmationFalseMethod"
            ></RemFormConfirmationModal>
            <!-- eslint-enable -->
          </template>
        </RemFormLayout>
      </template>
    </BaseLayout>
  </div>
</template>

<script setup>
import BaseLayout from '../layouts/BaseLayout.vue';
import ImFormInput from '../components/ImFormInput.vue';
import ImFormLabel from '../components/ImFormLabel.vue';
import EditDocumentModal from '../components/REM/EditDocumentModal.vue';
import RobberVueGrid from '@/components/RVG/RobberVueGrid.vue';
import RemUpButton from '../components/REM/RemUpButton.vue';
import SideMenuButton from '../components/REM/SideMenuButton.vue';
import RemFormButtonRowStored from '../components/REM/RemFormButtonRowStored.vue';
import RemFormLayout from '../components/REM/RemFormLayout.vue';
import RemFormCard from '../components/REM/RemFormCard.vue';
import RemFormConfirmationModal from '../components/REM/RemFormConfirmationModal.vue';
import PremiseBasicdataForm from './PremisePageComponents/PremiseBasicdataForm.vue';
import RemFormBackbutton from '../components/REM/RemFormBackbutton.vue';
import RemPrimaryButton from '../components/REM/RemPrimaryButton.vue';
import RemRVGForm from '../components/REM/RemRVGForm.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import RemPartnerAssignSearch from '../components/REM/RemPartnerAssignSearch.vue';
import ImFormInputVisibilityWraped from '../components/REM/ImFormInputVisibilityWraped.vue';
import RemVisibilityWrapper from '../components/REM/RemVisibilityWrapper.vue';

//Utils
import { ref, watch, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import route from '@/misc/router';
import premiseUtils from './PremisePageComponents/premiseUtils.js';
import store from '@/misc/vuex-store';
import { useI18n } from 'vue-i18n';
import Utils from '../misc/Utils.js';
import remFormUtils from '../misc/remFormUtils.js';
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import { computedAsync } from '@vueuse/core';
import RVGUtils from '../components/RVG/RobberVueGridUtils.js';
import toast from '@/misc/toast';
import User from '@/misc/User';

//FormData
import premiseUtilizationData from '../misc/remFormdata/premise/premiseUtilizationData.js';
import premiseSpecialData from '../misc/remFormdata/premise/premiseSpecialData.js';
import premiseLegalSituationData from '../misc/remFormdata/premise/premiseLegalSituationData.js';
import premiseMeterData from '../misc/remFormdata/premise/premiseMeterData.js';
import premisePartnerData from '../misc/remFormdata/premise/premisePartnerData.js';
import premiseCoolingHeatingData from '../misc/remFormdata/premise/premiseCoolingHeatingData.js';

//RVG Configs
import documentsGridConfig from '@/misc/gridConfigs/premisePage/premiseDocumentsGridConfig.js';
import utilizationGridConfig from '@/misc/gridConfigs/premisePage/premiseUtilizationGridConfig.js';
import legalsitGridConfig from '@/misc/gridConfigs/premisePage/premiseLegalsitGridConfig.js';
import meterGridConfig from '@/misc/gridConfigs/premisePage/premiseMeterGridConfig.js';
import partnersGridConfig from '@/misc/gridConfigs/premisePage/premisePartnerGridConfig.js';
import CHDGridConfig from '@/misc/gridConfigs/premisePage/premiseCHDGridConfig.js';
import linkedTasksGridConfig from '@/misc/gridConfigs/premisePage/linkedTasksGridConfig';

/**
 * Setup
 */
const legalsitGrid = ref();
const utilizationGrid = ref();
const meterGrid = ref();
const documentsGrid = ref();
const partnersGrid = ref();
const chdGrid = ref();

const partnerMainTypes = ref([]);
const partnerSubTypes = ref([]);
const partnerMainTypeMapping = computed(() => {
  return RVGUtils.getFormatterMappingForOptionValues(partnerMainTypes.value);
});
const partnerSubTypeMapping = computed(() => {
  return RVGUtils.getFormatterMappingForOptionValues(partnerSubTypes.value);
});

const { t } = useI18n();

const language = computed(() => store.getters.getLanguage);

const router = useRoute();
const premiseId = router.params.premiseId;
const propertyId = router.params.propertyId;
const buildingId = router.params.buildingId;
const documentFile = ref('');
const disabled = ref(true);
const disabledContractId = ref(true);

const mainPremise = async () => {
  const resp = await Utils.fetchPremiseById(premiseId);
  state.value.mainPremise = resp;
};
const getBuilding = async () => {
  const resp = await Utils.fetchBuildingById(buildingId);
  state.value.building = resp;
};
store.commit('setRemTab', 1);

const state = ref({
  tabsToConfirm: {
    1: {
      saveMethod: async () => {
        await savePremise();
      },
      cancelMethod: () => {
        state.value.setPremiseBack = state.value.setPremiseBack + 1;
      },
    },
    8: {
      saveMethod: async () => {
        await saveLegalsit();
      },
    },
    7: {
      saveMethod: async () => {
        await saveUtilization();
      },
    },
    4: {
      saveMethod: async () => {
        await savePremiseSpecial();
      },
      cancelMethod: () => {
        remFormUtils.setBackData(state.value.specialData);
      },
    },
    12: {
      saveMethod: async () => {
        await saveMeter();
      },
    },
    15: {
      saveMethod: async () => {
        await savePartner();
      },
    },
    17: {
      saveMethod: async () => {
        await saveCoolingHeatingDevice();
      },
      cancelMethod: () => {
        remFormUtils.setBackData(state.value.coolingHeatingDeviceData);
      },
    },
  },
  premiseSave: 0,
  setPremiseBack: 0,
  showConfirmation: false,
  //editLegalsit: formdata.editLegalsit,

  mainPremise: {},
  building: {},

  docuIdForEdit: '',
  documentSetup: {
    documentName: '',
    documentDateOfOrigin: '',
    documentType: '',
    documentSubType: '',
    documentTypeLabel: '',
    documentSubTypeLabel: '',
    documentSubTypeParent: '',
    contractIdentifier: '',
  },
  documentPreview: {
    title: '',
    id: '',
    url: '',
    open: false,
  },
  confirmation: {
    showConfirmation: false,
    confirmationTextToken: '',
    confirmationTrueMethod: () => {},
    confirmationFalseMethod: () => {},
  },

  specialData: premiseSpecialData.data,

  utilizationData: premiseUtilizationData.data,
  utilizationId: 0,

  legalsitData: premiseLegalSituationData.data,
  legalsitId: 0,

  meterData: premiseMeterData.data,
  meterId: 0,

  partnerData: premisePartnerData.data,
  partnerId: 0,
  isPerson: false,

  coolingHeatingDeviceData: premiseCoolingHeatingData.data,
  coolingHeatingDeviceId: 0,

  property: null,
});

//state.value.partnerData[2].parent = state.value.partnerData[1].value

/**
 * COMPUTED
 */

const allowedExtensions = computed(() => {
  return store.getters.getAllowedExtensions;
});

const modalStatus = computed(() => {
  return store.getters.getIsModalOpen;
});

const getTypeMappings = async () => {
  [partnerMainTypes.value, partnerSubTypes.value] = await Promise.all([
    Utils.getAllOptions('PARTNER_MAIN_TYPE'),
    Utils.getAllOptions('PARTNER_SUB_TYPE'),
  ]);
};

onMounted(() => {
  getProperty();
  getBuilding();
  mainPremise();
  getTypeMappings();
  premiseUtils.asyncGet(state, premiseId);
});

const deletePartner = (recordId, cbToRefreshGrid) => {
  remFormUtils.deattachConfirmation(
    state,
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      '/rem/premise/' +
      premiseId +
      '/partner/' +
      recordId,
    'partner',
    14,
    t,
    cbToRefreshGrid
  );
};

/**
 *  RVGs
 */

//LINKED TASKS
const linkedTasksGridConfigComputed = computedAsync(async () => {
  return linkedTasksGridConfig(state, t, premiseId);
});

const documentsGridConfigComputed = computedAsync(async () => {
  const [typeMapping, subTypeMapping] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionType('DOCUMENT_TYPE'),
    RVGUtils.getFormatterMappingForOptionType('DOCUMENT_SUB_TYPE'),
  ]);
  return documentsGridConfig(premiseId, state, t, editDocument, typeMapping, subTypeMapping);
}, documentsGridConfig(premiseId, state, t, editDocument));

const utilizationGridConfigComputed = computedAsync(async () => {
  const [legalRelationshipMapping] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionType('LEGAL_RELATIONSHIP_TYPE'),
  ]);
  return utilizationGridConfig(premiseId, state, t, legalRelationshipMapping);
}, utilizationGridConfig(premiseId, state, t));

const legalsitGridConfigComputed = computedAsync(async () => {
  const [legalRelationshipTypeMapping, legalSituationTypeMapping] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionType('LEGAL_RELATIONSHIP_TYPE'),
    RVGUtils.getFormatterMappingForOptionType('LEGAL_SITUATION'),
  ]);
  return legalsitGridConfig(
    premiseId,
    state,
    t,
    legalRelationshipTypeMapping,
    legalSituationTypeMapping
  );
}, legalsitGridConfig(premiseId, state, t));

const meterGridConfigComputed = computedAsync(async () => {
  return await meterGridConfig(premiseId, state, 'meter', t);
});

//PARTNERS
const partnersGridConfigComputed = computed(() => {
  return partnersGridConfig(
    state,
    t,
    premiseId,
    deletePartner,
    partnerMainTypeMapping.value,
    partnerSubTypeMapping.value
  );
});

const savePartner = () => {
  premiseUtils.savePartner(state, t, premiseId);
  partnersGrid.value.refresh();
};

//CHD
const CHDGridConfigComputed = computedAsync(async () => {
  return CHDGridConfig(premiseId, state, 'coolingHeatingDevice', t);
});

function setRoute(name) {
  if (!store.getters.getRemFormEdit) {
    route.push({ name: name });
  }
}

/**
 *  Watchers
 */

watch(
  () => language.value,
  () => {
    premiseUtils.asyncGet(state, premiseId);
  }
);

watch(
  () => store.getters.getRemFormEdit,
  () => {
    getBuilding();
    mainPremise();
  }
);

watch(
  () => state.value.documentSetup.documentType,
  () => {
    state.value.documentSetup.documentSubTypeParent = state.value.documentSetup.documentType;
    disabled.value = false;
    if (!state.value.documentSetup.documentType) {
      state.value.documentSetup.documentSubType = null;
      disabled.value = true;
    }
  }
);

watch(
  () => state.value.documentSetup.documentSubType,
  () => {
    disabledContractId.value = false;
    if (!state.value.documentSetup.documentSubType) {
      state.value.documentSetup.contractIdentifier = null;
      disabledContractId.value = true;
    }
  }
);

watch(
  () => state.value.coolingHeatingDeviceData[0].value,
  () => {
    if (store.getters.getRemTab == 17 && store.getters.getRemFormEdit == true) {
      setTimeout(() => {
        state.value.coolingHeatingDeviceData[1].parent =
          state.value.coolingHeatingDeviceData[0].value;
      }, 700);
    }
  }
);

watch(
  () => state.value.partnerData[1].value,
  () => {
    if (store.getters.getRemTab == 15 && store.getters.getRemFormEdit == true) {
      setTimeout(() => {
        state.value.partnerData[2].parent = state.value.partnerData[1].value;
      }, 700);
    }
    if (state.value.partnerData[1].value != state.value.partnerData[2].parent) {
      state.value.partnerData[2].value = null;
    }
  }
);

watch(
  () => state.value.partnerData[3].value,
  () => {
    setTimeout(() => {
      state.value.partnerData[4].parent = state.value.partnerData[3].value;
      state.value.partnerData[5].parent = state.value.partnerData[3].value;
    }, 700);
  }
);

/**
 * Savers
 */

function savePremise() {
  state.value.premiseSave = state.value.premiseSave + 1;
}

async function savePremiseSpecial() {
  const result = await premiseUtils.asyncSavePremiseSpecialData(state, premiseId, t);
  return result;
}

async function uploadFile() {
  if (!state.value.documentSetup.documentType) {
    toast.error(t('message.mainType') + t('message.mandatoryField'));
    return;
  } else if (!state.value.documentSetup.documentSubType) {
    toast.error(t('message.subType') + t('message.mandatoryField'));
    return;
  }

  if (
    !state.value.documentSetup.documentDateOfOrigin ||
    state.value.documentSetup.documentDateOfOrigin == ''
  ) {
    toast.error(t('message.dateOfOrigin') + t('message.mandatoryField'));
    return;
  }

  if (!documentFile.value.files[0]) {
    toast.error(t('message.document') + t('message.choosingMandatory'));
    return;
  }

  await premiseUtils.uploadFile(state, documentFile, premiseId, t, allowedExtensions);
  const fileInput = document.getElementById('file_input');
  fileInput.value = '';
  documentsGrid.value.refresh();
}

async function editDocument(id) {
  const response = await Utils.fetchDocumentById(id);

  store.commit('setIsModalOpen', true);
  store.commit('setDocumentById', response);
  state.value.docuIdForEdit = id;
}

async function saveLegalsit() {
  const result = await premiseUtils.saveLegalsit(state, premiseId, t);
  legalsitGrid.value.refresh();
  return result;
}

async function saveMeter() {
  const result = await premiseUtils.saveMeter(state, premiseId, t);
  meterGrid.value.refresh();
  return result;
}

async function saveUtilization() {
  if (
    state.value.utilizationData[3].value != '' &&
    state.value.utilizationData[2].value > state.value.utilizationData[3].value
  ) {
    toast.error(t('message.startDateLaterThanEndDate'));
    return false;
  }
  const result = premiseUtils.saveUtilization(state, premiseId, t);
  utilizationGrid.value.refresh();
  return result;
}

async function saveCoolingHeatingDevice() {
  const result = await premiseUtils.saveCoolingHeatingDevice(state, premiseId, t);
  chdGrid.value.refresh();
  return result;
}

function deleteUtilization() {
  deleteConfirmation(
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      '/rem/utilization/' +
      state.value.utilizationId,
    'utilization',
    3
  );
  utilizationGrid.value.refresh();
}

function deleteMeter() {
  deleteConfirmation(
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + `/rem/meter/${state.value.meterId}`,
    'meter',
    11
  );
  meterGrid.value.refresh();
}

function deleteCoolingHeatingDevice() {
  deleteConfirmation(
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      `/rem/premise/${premiseId}/cooling-heating-device/${state.value.coolingHeatingDeviceId}`,
    'coolingHeatingDevice',
    13
  );
  chdGrid.value.refresh();
}

function deleteLegalSit() {
  deleteConfirmation(
    TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
      '/rem/legal-situation/' +
      state.value.legalsitId,
    'legalSit',
    2
  );
  legalsitGrid.value.refresh();
}

function deleteConfirmation(endpoint, formData, tab, cb, tokenPrefix = 'delete') {
  remFormUtils.deleteConfirmation(state, endpoint, formData, tab, t, cb, tokenPrefix);
}

function setTab(tab, name) {
  remFormUtils.setTab(state, tab, name);
}
function addNewTab(name, tab) {
  remFormUtils.addNewTab(name, tab, state);
}

// const setConfirmation = (id) => {
//   state.value.docuIdForEdit = id
//   state.value.showConfirmation = true;
// };

const deleteDocument = () => {
  premiseUtils.deleteDocument(state.value.docuIdForEdit, t);
};

const getProperty = async () => {
  const response = await Utils.fetchPropertyById(propertyId);
  state.value.property = response;
};
</script>

<style scoped>
.th {
  height: 2.3rem;
}

.dp__theme_dark {
  --dp-background-color: hsl(220, 17.647%, 20%);
  @apply border border-gray-700 rounded-md text-sm;
}

input[type='file'],
textarea {
  background-color: hsl(220, 17.647%, 20%);
}

.im-preview-modal-box {
  width: 75%;
  min-width: 75%;
  height: 75%;
}

.im-mandatory {
  color: red;
}
</style>
