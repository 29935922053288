let uniqueField = "premiseGrid";

import TenantConfigHandler from '@/misc/TenantConfigHandler';
import store from "@/misc/vuex-store";

export default (state, t, buildingId, toPremiseFn, basicDataPremiseFunctionMapping, basicDataCremClassMapping, basicDataStairwayTypeMapping, basicDataElevatorTypeMapping, basicDataFloorMapping, specialDataFloorCoveringMapping, specialDataFalseCeilingMapping, specialDataDoorTypeMapping, specialDataWindowMaterialMapping, specialDataSzepaMapping,) => {
  return {
    endpoint:
    TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/building/${buildingId}/premises`,
    idkey: "id",
    defaultOrderKey: "id",
    defaultOrderDirection: "desc",
    orderLocal: false,
    rootkey: "content",
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      possiblePageSizes: [10, 20, 50, 100],
    },
    filtering: {
      active: true,
      local: false,
      //simple: true,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    bulkOperation: {
      active: false,
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: "server", // server or local/null
      serverGridKey: "worksGridColumns",
    },
    xlsxExport: {
      active: true,
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [{
        key: '30sec',
        refreshInterval: 30,
        title: t('message.rvg-automatic-list-refresh-30-sec'),
        default: true
      }, {
        key: '1min',
        refreshInterval: 60,
        title: t('message.rvg-automatic-list-refresh-1-min'),
      }, {
        key: '5min',
        refreshInterval: 300,
        title: t('message.rvg-automatic-list-refresh-5-min'),
      }]
    },
    mapping: {
      $operations: {
        title: "",
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: "50px",
        formatter: {
          type: "Operations",
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];

              buttons.push({
                title: '',
                tooltip: t('message.open'),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12,8V4L20,12L12,20V16H4V8H12Z" /></svg>',
                classList: 'btn btn-secondary btn-sm hover:!opacity-80 btn-circle',
                testValueAttribute: 'buildingForwardButton',
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  toPremiseFn(record.id)
                }
              })

              buttons.push({
                title: '',
                tooltip: t('message.edit'),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12 3L2 12H5V20H10V14H14V15.11L19.43 9.68L12 3M21.04 11.14C20.9 11.14 20.76 11.2 20.65 11.3L19.65 12.3L21.7 14.35L22.7 13.35C22.91 13.14 22.91 12.79 22.7 12.58L21.42 11.3C21.32 11.2 21.18 11.14 21.04 11.14M19.06 12.88L13 18.94V21H15.06L21.11 14.93L19.06 12.88Z" /></svg>',
                classList: 'btn btn-secondary btn-sm hover:!opacity-80 btn-circle',
                testValueAttribute: 'premiseEditButton',
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {     
                  state.value.premiseEdit = true;       
                  state.value.premiseId = record.id;
                    state.value.premiseData.forEach((e) => {
                      if (e.type == "label") return;
                      if (e.type == "option") {          
                        e.value = record["basicData"+e.model.charAt(0).toUpperCase()+e.model.slice(1) + "Id"];
                        e.valueLabel = record["basicData"+e.model.charAt(0).toUpperCase()+e.model.slice(1) + "Value"];
                      } else {
                        e.value = record["basicData"+e.model.charAt(0).toUpperCase()+e.model.slice(1)];
                      }
                    });

                      state.value.elevatorData.forEach((e) => {
                        if (e.type == "label") return;
                        if (e.type == "option") {          
                          e.value = record[e.model + "Id"];
                          e.valueLabel = record[e.model + "Value"];
                        } else {
                          e.value = record[e.model];
                        }
                      });

                      state.value.staircaseData.forEach((e) => {
                        if (e.type == "label") return;
                        if (e.type == "option") {          
                          e.value = record[e.model + "Id"];
                          e.valueLabel = record[e.model + "Value"];
                        } else {
                          e.value = record[e.model];
                        }
                      });

                      state.value.premiseSpecialData.forEach((e) => {
                        if (e.type == "label") return;
                        if (e.type == "option") {          
                          e.value = record["specialData"+e.model.charAt(0).toUpperCase()+e.model.slice(1) + "Id"];
                          e.valueLabel = record["specialData"+e.model.charAt(0).toUpperCase()+e.model.slice(1) + "Value"];
                        } else {
                          e.value = record["specialData"+e.model.charAt(0).toUpperCase()+e.model.slice(1)];
                        }
                      });

                      store.commit("setRemTab", 10)
                      store.commit("setRemFormEdit", false)
                }
              })

              return buttons;
            },
          },
        },
      },
      "id": {
        title: "ID",
        visible: false,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Number'
        },
        exportable: false,
        orderable: true,
      },
      "basicDataPremiseFunctionValue": {
        title: t("message.function"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataPremiseFunctionValue ? record.basicDataPremiseFunctionValue : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataPremiseFunctionMapping,
        },
      },
      "basicDataCremClassValue": {
        title: t("message.cremClassification"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataCremClassValue ? record.basicDataCremClassValue : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataCremClassMapping,
        },
      },
      "basicDataIdentifier": {
        title: t("message.identifier"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataIdentifier ? record.basicDataIdentifier : "N/A";
        },
      },
      "basicDataFloorValue": {
        title: t("message.level"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataFloorValue ? record.basicDataFloorValue : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataFloorMapping,
        },
      },
      "basicDataPremiseNumber": {
        title: t("message.premiseNumber"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataPremiseNumber ? record.basicDataPremiseNumber : "N/A";
        },
      },
      "basicDataFloorArea": {
        title: t("message.floorArea"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      "basicDataStairwayIdentifier": {
        title: t("message.stairwayId"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataStairwayIdentifier ? record.basicDataStairwayIdentifier : "N/A";
        },
      },
      "basicDataStairwayTypeValue": {
        title: t("message.stairwayType"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataStairwayTypeValue ? record.basicDataStairwayTypeValue : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataStairwayTypeMapping,
        },
      },
      "basicDataSmokeFree": {
        title: t("message.isSmokeFree"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status", //boolean
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            "true": {
              title: t('message.yes')
            },
            "false": {
              title: t('message.no')
            },
            "null": {
              title: 'N/A'
            }
          }
        }
      },
      "basicDataFireRoute": {
        title: t("message.isEscapeRoute"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status", //boolean
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            "true": {
              title: t('message.yes')
            },
            "false": {
              title: t('message.no')
            },
            "null": {
              title: 'N/A'
            }
          }
        }
      },
      "basicDataElevatorNumberPersons": {
        title: t("message.numberOfPerson"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Integer",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataElevatorNumberPersons ? record.basicDataElevatorNumberPersons : "N/A";
        },
      },
      "basicDataElevatorLoadCapacity": {
        title: t("message.loadCapacity"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataElevatorLoadCapacity? record.basicDataElevatorLoadCapacity : "N/A";
        },
      },
      "basicDataElevatorTypeValue": {
        title: t("message.elevatorType"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.basicDataElevatorTypeValue ? record.basicDataElevatorTypeValue : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: basicDataElevatorTypeMapping,
        },
      },
      "basicDataEmergencyElevator": {
        title: t("message.isEmergencyElevator"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status", //boolean,
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            "true": {
              title: t('message.yes')
            },
            "false": {
              title: t('message.no')
            },
            "null": {
              title: 'N/A'
            }
          }
        }
      },
      "basicDataAccessibility": {
        title: t("message.isAccessible"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status", //boolean
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            "true": {
              title: t('message.yes')
            },
            "false": {
              title: t('message.no')
            },
            "null": {
              title: 'N/A'
            }
          }
        }
      },
      "specialDataFloorCoveringValue": {
        title: t("message.floorCovering"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.specialDataFloorCoveringValue ? record.specialDataFloorCoveringValue : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: specialDataFloorCoveringMapping,
        },
      },
      "specialDataFalseFloor": {
        title: t("message.isFalseFloor"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        formatter: {
          type: 'Status',
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            "true": {
              title: t('message.yes')
            },
            "false": {
              title: t('message.no')
            },
            "null": {
              title: 'N/A'
            }
          }
        },
        exportable: true,
        orderable: true,
       
       
      },
      "specialDataFalseCeilingValue": {
        title: t("message.isFalseCeiling"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.specialDataFalseCeilingValue ? record.specialDataFalseCeilingValue : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: specialDataFalseCeilingMapping,
        },
      },
      "specialDataNumberDedicated": {
        title: t("message.numberDedicated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      "specialDataNumberCanBeSeated": {
        title: t("message.numberCanBeSeated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      "specialDataNumberSeated": {
        title: t("message.numberSeated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      "specialDataEntryNote": {
        title: t("message.notes"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.specialDataEntryNote? record.specialDataEntryNote : "N/A";
        },
      },
      "specialDataDoorTypeValue": {
        title: t("message.doorType"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.specialDataDoorTypeValue ? record.specialDataDoorTypeValue : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: specialDataDoorTypeMapping,
        },
      },
      "specialDataOpenableWindow": {
        title: t("message.openableWindow"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status", //boolean
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            "true": {
              title: t('message.yes')
            },
            "false": {
              title: t('message.no')
            },
            "null": {
              title: 'N/A'
            }
          }
        }
      },
      "specialDataWindowMaterialValue": {
        title: t("message.windowMaterial"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.specialDataWindowMaterialValue ? record.specialDataWindowMaterialValue : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: specialDataWindowMaterialMapping,
        },
      },
      "specialDataNumberOfWindows": {
        title: t("message.windowNumber"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      "specialDataWindowWidth": {
        title: t("message.windowWidth"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      "specialDataWindowHeight": {
        title: t("message.windowHeight"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      "specialDataWindowDepth": {
        title: t("message.windowDepth"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      "specialDataMinHeight": {
        title: t("message.minHeight"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      "specialDataMaxHeight": {
        title: t("message.maxHeight"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      "specialDataSzepaValue": {
        title: t("message.szepaClass"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.specialDataSzepaValue ? record.specialDataSzepaValue : "N/A";
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: specialDataSzepaMapping,
        },
      },
      "specialDataSharedUse": {
        title: t("message.sharedUse"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status", //boolean
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            "true": {
              title: t('message.yes')
            },
            "false": {
              title: t('message.no')
            },
            "null": {
              title: 'N/A'
            }
          }
        }
      },
      "specialDataDedicatedOrgUnit": {
        title: t("message.dedicatedOrgUnit"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status", //boolean
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            "true": {
              title: t('message.yes')
            },
            "false": {
              title: t('message.no')
            },
            "null": {
              title: 'N/A'
            }
          }
        }
      },
      "specialDataCanBeHeated": {
        title: t("message.canBeHeated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status", //boolean
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            "true": {
              title: t('message.yes')
            },
            "false": {
              title: t('message.no')
            },
            "null": {
              title: 'N/A'
            }
          }
        }
      },
      "specialDataCanBeCooled": {
        title: t("message.canBeCooled"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Status", //boolean
        },
        exportable: true,
        orderable: true,
        formatter: {
          type: 'Status',
          dataType: "boolean", // could be: integer, text, boolean
          mapping: {
            "true": {
              title: t('message.yes')
            },
            "false": {
              title: t('message.no')
            },
            "null": {
              title: 'N/A'
            }
          }
        }
      },
    }, 
  };
};
