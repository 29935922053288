import Axios from '@/misc/axios';
import toast from '@/misc/toast.js';
//import { watchEffect /*onMounted, reactive,*/ } from "vue";
import TenantConfigHandler from '@/misc/TenantConfigHandler';
//import Utils from "../../misc/Utils";
import remFormUtils from '../../misc/remFormUtils';
import store from '@/misc/vuex-store';

export default {
  // setup scripts

  asyncGet: async function (state, premiseId) {
    remFormUtils.getSingleton(
      state,
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
        '/rem/premise/' +
        premiseId +
        '/special-data',
      'special'
    );
  },

  //savers

  asyncSavePremiseSpecialData: async function (state, premiseId, t) {
    console.log('12');
    const hermes = {};
    state.value.specialData.forEach((e) => {
      if (e.type == 'label') {
        return;
      } else {
        hermes[e.model] = e.value === '' ? null : e.value;
      }
    });

    //const hermes = state.value.orig.specialData;
    remFormUtils.onlyPost(
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
        '/rem/premise/' +
        premiseId +
        '/special-data',
      hermes,
      'special',
      store.getters.getRemTab,
      state,
      t
    );
  },
  uploadFile: async function (state, documentFile, premiseId, t, allowedExtensions) {
    const formData = new FormData();

    formData.append('mainType', state.value.documentSetup.documentType);
    formData.append('subType', state.value.documentSetup.documentSubType);
    formData.append('document', documentFile.value.files[0]);
    formData.append('name', state.value.documentSetup.documentName);
    formData.append('dateOfOrigin', state.value.documentSetup.documentDateOfOrigin);
    formData.append('contractIdentifier', state.value.documentSetup.contractIdentifier);
    try {
      const resp = await Axios.post(
        TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
          `/rem/premise/${premiseId}/document`,
        formData
      );
      if (resp.status == 200) {
        toast.success(t('message.successfulSave'));
        documentFile.value = '';
        state.value.documentSetup = {
          documentName: '',
          documentDateOfOrigin: '',
          documentType: '',
          documentSubType: '',
          documentTypeLabel: '',
          documentSubTypeLabel: '',
          contractIdentifier: '',
        };
      } else {
        toast.error(t('message.saveNotSuccessful'));
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 406) {
        toast.error(
          t('message.fileFormatError') + allowedExtensions.value + t('message.fileFormatError2')
        );
      } else if (error.response.status == 413) {
        toast.error(t('message.fileSizeError'));
      } else {
        toast.error(t('message.saveError'));
      }
    }
  },
  deleteDocument: async function (id, t) {
    const uri = TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + `/rem/document/${id}`;

    try {
      await Axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deleteDocument(id, t);
        },
      });
      toast.success(t('message.deleteSuccessful'));
    } catch (error) {
      toast.error(t('message.deleteNotSuccessful'));
    }
  },
  saveLegalsit: async function (state, premiseId, t) {
    const hermes = {
      legalSituationType: '',
      legalRelationshipType: '',
      ownership: '',
      legalRelationshipStart: '',
      legalRelationshipEnd: '',
      floorArea: '',
    };

    state.value.legalsitData.forEach((e) => {
      hermes[e.model] = e.value === '' ? null : e.value;
    });

    hermes.premiseId = premiseId;

    return await remFormUtils.putPost(
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/rem/legal-situation',
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
        '/rem/legal-situation/' +
        state.value.legalsitId,
      hermes,
      'legalsit',
      2,
      state,
      t
    );
  },
  saveMeter: async function (state, premiseId, t) {
    const hermes = {};

    state.value.meterData.forEach((e) => {
      hermes[e.model] = e.value === '' ? null : e.value;
    });

    hermes.premiseId = premiseId;

    return await remFormUtils.putPost(
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/rem/meter',
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/rem/meter/' + state.value.meterId,
      hermes,
      'meter',
      11,
      state,
      t
    );
  },
  saveUtilization: async function (state, premiseId, t) {
    const hermes = {
      premiseId: premiseId,
      floorArea: '',
      legalRelationship: '',
      legalRelationshipStart: '',
      legalRelationshipEnd: '',
      partnerId: '',
    };

    state.value.utilizationData.forEach((e) => {
      hermes[e.model] = e.value === '' ? null : e.value;
    });

    if (state.value.utilizationId != 0) {
      hermes.id = state.value.utilizationId;
    }

    return await remFormUtils.putPost(
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/rem/utilization',
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
        '/rem/utilization/' +
        state.value.utilizationId,
      hermes,
      'utilization',
      3,
      state,
      t
    );
  },
  saveCoolingHeatingDevice: async function (state, premiseId, t) {
    const hermes = {};

    state.value.coolingHeatingDeviceData.forEach((e) => {
      if (e.type == 'label') {
        return;
      } else {
        hermes[e.model] = e.value === '' ? null : e.value;
      }
    });

    //console.log(hermes)
    // remFormUtils.onlyPost(
    //   TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
    //     `/rem/premise/${premiseId}/cooling-heating-device`,
    //   hermes,
    //   "coolingHeatingDevice",
    //   store.getters.getRemTab,
    //   state,
    //   t
    // );

    return await remFormUtils.putPost(
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
        `/rem/premise/${premiseId}/cooling-heating-device`,
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
        `/rem/premise/${premiseId}/cooling-heating-device/` +
        state.value.coolingHeatingDeviceId,
      hermes,
      'coolingHeatingDevice',
      13,
      state,
      t
    );
  },
  savePartner: async function (state, t, id) {
    const hermes = {
      partnerMainType: 0,
      partnerSubType: 0,
      name: '',
      taxId: '',
      taxNumber: '',
    };

    state.value.partnerData.forEach((e) => {
      if (e.model != '') {
        hermes[e.model] = e.value === '' ? null : e.value;
      }
    });

    if (state.value.partnerId != 0) {
      hermes.id = state.value.partnerId;
    }

    let errorMessage = '';
    if (hermes.name == '' || hermes.name == null) {
      errorMessage = t('message.missingFormFields');
    } else {
      errorMessage = t('message.notSuccessfulSave');
    }

    return await remFormUtils.putPost(
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + `/rem/premise/${id}/partner`,
      TenantConfigHandler.getVariable('FRONTENDV2_API_URL') +
        '/rem/partner/' +
        state.value.partnerId,
      hermes,
      'partner',
      14,
      state,
      t,
      errorMessage
    );
  },
  deleteUniversal: async function (state, endpoint, formdata, tab, t) {
    try {
      let resp = await Axios.delete(endpoint, {
        onTokenRefreshed: () => {
          this.deleteUniversal(state, endpoint, formdata, tab, t);
        },
      });
      if (resp && resp.status == 204) {
        toast.success(t('message.deleteSuccessful'));
        state.value.tab = tab;
        state.value[`${formdata}Id`] = 0;
        state.value[`${formdata}Edit`] = false;
      } else {
        toast.error(t('message.deleteNotSuccessful'));
      }
    } catch (e) {
      toast.error(t('message.deleteNotSuccessful'));
    }
  },
};
